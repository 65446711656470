.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
  padding-left: 10px;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/*START RADIO BUTTON PG CODE*/
.inputGroup {
  background-color: white;
  display: block;
  margin: 10px 0;
  position: relative;
}

.inputGroup label {
  padding: 10px;
  font-size: 15px;
  width: 100%;
  display: block;
  text-align: left;
  color: #3C454C;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: color 200ms ease-in;
  overflow: hidden;
  font-weight: unset;
}

.inputGroup label:before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: '';
  background-color: #3c8dbc;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale3d(1, 1, 1);
  transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
  opacity: 0;
  z-index: -1;
}

.inputGroup input:checked ~ label {
  color: #fff;
}

.inputGroup input:checked ~ label::before {
  transform: translate(-50%, -50%) scale3d(56, 56, 1);
  opacity: 1;
}

.inputGroup input:checked ~ label::after {
  background-color: #54E0C7;
  border-color: #54E0C7;
}

.inputGroup input {
  width: 32px;
  height: 32px;
  order: 1;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;
}
/*END RADIO BUTTON PG CODE*/

/*copy to clipboard*/
.copytoclipboard {
  cursor: pointer;
  margin-left: 5px;
}
