.headerPaper,
.header-space,
.footerPaper,
.footer-space {
  height: 90px;
}
.headerPaper {
  position: fixed;
  top: 0;
}
.footerPaper {
  position: fixed;
  bottom: 0;
}
.tablePrint {
  border-collapse: collapse;
  table-layout: fixed;
}
.tablePrint td {
  width: 765px;
  border: 1px solid transparent;
  word-wrap: break-word;
}
@media print {
  .tablePrint tr.bg-grey {
    background-color: #eee !important;
    /* border: 1px solid #eee; */
    padding: 5px 10px;
    print-color-adjust: exact;
  }
  .tablePrint tr.bg-grey td {
    padding-bottom: 7px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
  }

  .tablePrint tr,
  .tablePrint th,
  .tablePrint td {
    border: 1px solid transparent;
  }
}

@media print {
  .bg-grey th {
    color: white !important;
  }
}
