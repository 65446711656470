.divContainter {
    margin: 10px;
    text-align: left;
    padding: 10px;
}

.red-bg .swal-text{
 color: red;
}

.table-custom td,th{
    padding: 3px 8px;
}

.rowHorizontal{display:flex; flex-direction:row; }
  